/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'

document.addEventListener('DOMContentLoaded', () => {
  const header = new Vue({
    el: '#header',
    data: {
        menu: 0
    },
    methods: {
        openMenu : function() {
            this.menu = 1
        },
        closeMenu : function () {
            this.menu = 0
        },
    },
    created() {
    },
    mounted() {
    },
    filters: {
    }
  }).$mount()
})

